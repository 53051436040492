export const data = function() {
  return {
    isDebug: true,
    loading: false,
    tabs: [
      { name: 'calendar', label: 'Calendar' },
      { name: 'documentCenter', label: 'Document Center' },
      { name: 'frequentlyAskedQuestions', label: 'Frequently Asked Questions' },
      { name: 'hoaContacts', label: 'HOA Contacts' }
    ],
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down'
  }
}
